import mixins from "../mixins";

const generateUID = mixins.generateUID.methods.generateUID;

export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "homepage",
        "pageId": 1,
        "sections": [
          { // For Desktop
            "sectionId": "orbg8s",
            "class": "desktop-only",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "position": "relative"
            },
            "fields": [
              { // Refresh Token
                "fieldType": "refresh-token",
                "fieldId": "ij3433"
              },
              { // DataLayer
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: "PageView",
                  fbEvent: "PageView"
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "padding": "0 0 0 0",
                    "border-radius": "16px",
                    "overflow": "hidden",
                    "margin": "56px 0 0 0"
                  },
                  "noContainer": true,
                  altText: 'bannerAltText'
                }
              },
              { // Steps Card
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "10",
                    "marginTop": "114px",
                    "marginBottom": "60px"
                  },
                  "styles": {
                    "justify-content": "center;",
                    "align-items": "center;"
                  },
                  "fields": [
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/select_product.svg",
                        "stepTitle": "Step 1",
                        "stepDescription": "Select your product",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/receipt.svg",
                        "stepTitle": "Step 2",
                        "stepDescription": "Upload a receipt",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/register.svg",
                        "stepTitle": "Step 3",
                        "stepDescription": "Register",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/reward.svg",
                        "stepTitle": "Step 4",
                        "stepDescription": "Get your money back",
                        "grid": {
                          "columns": 6
                        }
                      }
                    }
                  ]
                }
              },
              { // Button
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "marginTop": "40px",
                    "marginBottom": "140px"
                  },
                  "styles": {
                    "justify-content": "center ;"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "x1p9hx",
                      "properties": {
                        "grid": {
                          "columns": "3"
                        },
                        "text": "START REFUND",
                        "addClass": "primaryButton",
                        "disabled": false,
                        "action": [
                          {
                            "fn": "goToPage",
                            "params": "product-scan"
                          }
                        ]
                      }
                    }
                  ]
                }
              }

            ]
          },
          { // For Mobile
            "sectionId": "orbg8s",
            "class": "mobile-only",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "position": "relative"
            },
            "fields": [
              { // Refresh Token
                "fieldType": "refresh-token",
                "fieldId": "ij3433"
              },
              { // DataLayer
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: "PageView",
                  fbEvent: "PageView"
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "padding": "0 0 0 0",
                    "border-radius": "16px",
                    "overflow": "hidden",
                    "margin": "24px",
                    "width": "auto"
                  },
                  "noContainer": true
                }
              },
              { // Steps Card
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "marginTop": "24px"
                  },
                  "styles": {
                    "justify-content": "center;",
                    "align-items": "center;",
                    "width": "auto;"
                  },
                  "fields": [
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/select_product.svg",
                        "stepTitle": "Step 1",
                        "stepDescription": "Select your product",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/receipt.svg",
                        "stepTitle": "Step 2",
                        "stepDescription": "Upload a receipt",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/register.svg",
                        "stepTitle": "Step 3",
                        "stepDescription": "Register",
                        "grid": {
                          "columns": 6
                        }
                      }
                    },
                    {
                      "fieldType": "step-card-oralb",
                      "fieldId": "05m65j",
                      "properties": {
                        "titleColor": "var(--color-8)",
                        "borderColor": "var(--color-5)",
                        "imageSource": "/assets/steps/Desktop/reward.svg",
                        "stepTitle": "Step 4",
                        "stepDescription": "Get your money back",
                        "grid": {
                          "columns": 6
                        }
                      }
                    }
                  ]
                }
              },
              { // Button
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "marginTop": "40px",
                    "marginBottom": "80px"
                  },
                  "styles": {
                    "justify-content": "center ;",
                    "width": "auto;"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "x1p9hx",
                      "properties": {
                        "grid": {
                          "columns": "3"
                        },
                        "text": "START REFUND",
                        "addClass": "primaryButton",
                        "disabled": false,
                        "action": [
                          {
                            "fn": "goToPage",
                            "params": "product-scan"
                          }
                        ]
                      }
                    }
                  ]
                }
              }

            ]
          }
        ]
      },
      { // Product Scanner
        "pageName": "product-scan",
        "pageId": 11,
        "sections": [
          { // Step and Header
            "sectionId": "98j3bew9jm",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "paddingBottom": "70px"
            },
            "fields": [
              {
                "fieldType": "progress-bar",
                "fieldId": "x62vxc",
                "properties": {
                  "currentInterval": 1,
                  "currentStep": 1,
                  "stepCount": 3
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Scan the product",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Type in or scan the barcode and serial number",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Please enter the serial number and barcode number of your Braun products, take a photo of your promotional product or upload it using <b>Drag & Drop</b>. Please make sure that the barcode information of your promotional product is clearly visible.",
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              },
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/BraunEAN.png",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 12,
                    "marginTop": "15px"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "bn54gef",
            "fields": [
              { // Barcode Scanner
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12
                  },
                  "styles": {
                    "justify-content": "center ;",
                    "margin": "0px;",
                    "padding": "0px;"
                  },
                  "fields": [
                    { // Barcode Scanner
                      "fieldType": "barcode-scanner-oralb",
                      "fieldId": "56hythe",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          padding: 0
                        },
                        "successAction": {
                          "fn": "goToPage",
                          "params": "receipt-upload"
                        },
                        "texts": {
                          receiptUpload: 'Upload',
                          receiptUploadDescription: 'Upload<br>barcode image',
                          receiptUploadSubDescription: 'Drag & Drop',
                          barcodeScan: 'Scan',
                          stop: 'Stop',
                          barcodeScanDescription: 'Scan<br>barcode image',
                          barcodeScanSubDescription: '(the easiest)',
                          barcodeAdd: 'Add',
                          barcodeAddDescription: 'Add<br>barcode number',
                          wrongFormat: 'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.',
                          fileSizeLimit: 'The file is too large. Only files up to 16 MB are supported.',
                          notParticipating: 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?',
                          notParticipatingVideo: 'Leider scheint das Produkt nicht an der Aktion teilzunehmen. Bitte versuchen Sie, den Barcode in das obige Feld einzugeben.',
                          timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                          cameraNotFound: 'Bitte schalten Sie Ihre Kamera ein',
                          invalidBarCode: 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?'
                        },
                        "apiUrl": "https://national-cashback.justsnap.eu/api/",
                        "errorPageName": "barcode-error",
                        "cameraTimeOut": 20000,
                        "maxProductCount": 3
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
        "pageName": "receipt-upload",
        "pageId": 2,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              {
                "fieldType": "progress-bar",
                "fieldId": "x62vxc",
                "properties": {
                  "currentInterval": 2,
                  "currentStep": 1,
                  "stepCount": 3
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Upload receipt",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Upload a picture of your proof of purchase in this step.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.",
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "justifyContent": "center",
              "margin": "0",
              "marginTop": "90px"
            },
            "fields": [
              { // Recipt upload
                "fieldType": "receipt-processor",
                "fieldId": "klvakj",
                "properties": {
                  texts: {
                    choose: 'Choose a File',
                    receiptUpload1: 'Upload your receipt photo',
                    receiptUpload2: 'Drag & Drop or click here',
                    wrongFormat: 'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.',
                    fileSizeLimit: 'The file is too big. Only files up to 16 MB are supported.'
                  },
                  "colorSchema": "#6BB1FF",
                  "grid": {
                    "columns": "6",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "addClass": "col-sm-9 col-md-6",
                  "successAction": {
                    "fn": "goToPage",
                    "params": "product-upload-be"
                  },
                  "phrases": [
                    "Your receipt will now be uploaded and processed.",
                    "The receipt is processed automatically.This can take a minute.Please be patient.",
                    "If you get an error message, please take a better version of the receipt and upload it again."
                  ],
                  "cornerDetectionPhrases": [
                    "Please drag your receipt into the gray rectangle.",
                    "You can erase private information with the razor tool."
                  ],
                  "eraseColor": "#ffffff",
                  "eraseSize": 12,
                  "cropColor": "#3A96F3",
                  "cropBackground": "rgba(0,0,0,0.6)",
                  "cropTitle": "Crop",
                  "eraseTitle": "Erase",
                  "dragTitle": "Move",
                  "zoomInTitle": "Zoom In",
                  "zoomOutTitle": "Zoom Out",
                  "selectImageTitle": "Choose a File",
                  "selectAnotherTitle": "Choose Another",
                  "controlsBackground": "#4193d3",
                  "backgroundColorBtn": "var(--color2)"
                }
              },
              { // Receipt Info
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 6,
                    "margin": "0px auto"
                  },
                  "classes": "receiptUploader",
                  "fields": [
                    {
                      "fieldType": "receipt-information",
                      "fieldId": "5mq1p6",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "descriptionText": "Please photograph your proof of purchase!",
                        "descriptionSubText": "Make sure that the following information is clearly visible:",
                        "steps": [
                          {
                            "text": "Dealer / Market name",
                            "label": "A"
                          },
                          {
                            "text": "Promotional product and purchase price",
                            "label": "B"
                          },
                          {
                            "text": "Total of the proof of purchase and VAT",
                            "label": "C"
                          },
                          {
                            "text": "Date and time of purchase",
                            "label": "D"
                          },
                          {
                            "text": "Proof of purchase ID",
                            "label": "E"
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Receipt Examples
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "margin": "0px auto"
                  },
                  "styles": {
                    "justify-content": "center;"
                  },
                  "classes": "receiptUploader",
                  "fields": [
                    {
                      "fieldType": "receipt-examples",
                      "fieldId": "5mq1p6",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "fn": "goToPage",
                    "params": "product-scan"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "NEXT",
                  "addClass": "primaryButton",
                  "shouldCheckCrop": true,
                  "requires": ["klvakj"],
                  "action": {
                    "fn": "sendReceipt",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      },
      { // Product Upload back side
        "pageName": "product-upload-be",
        "pageId": 252,
        "sections": [
          { // Steps and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "vgw364bh",
            "fields": [
              {
                "fieldType": "progress-bar",
                "fieldId": "x62vxc",
                "properties": {
                  "currentInterval": 2,
                  "currentStep": 2,
                  "stepCount": 3
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "2fvjh65",
                "properties": {
                  "text": "Upload product photo",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // SubHeading
                "fieldType": "paragraph",
                "fieldId": "b893274rf",
                "properties": {
                  "text": "Please upload a photo of the product side on which the EAN barcode is clearly visible.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              },
              { // SubHeading
                "fieldType": "paragraph",
                "fieldId": "b893274rf",
                "properties": {
                  "text": "Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.",
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              }
            ]
          },
          { // Product Uploader
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "90px",
              "marginBottom": "90px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "5"
                  },
                  "styles": {
                    "justifyContent": "center !important",
                    "maxWidth": "871px !important",
                    padding: 0
                  },
                  "fields": [
                    {
                      "fieldType": "product-upload",
                      "fieldId": "3sxumo",
                      "properties": {
                        texts: {
                          choose: 'Choose a File',
                          receiptUpload1: 'Upload product photo',
                          receiptUpload2: 'Drag & Drop or click here',
                          wrongFormat: 'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.',
                          fileSizeLimit: 'The file is too big. Only files up to 16 MB are supported.'
                        },
                        "colorSchema": "var(--color2)",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "apiUrl": "https://template-v2.justsnap.eu/api/uploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "register"
                        }
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "5"
                  },
                  "styles": {
                    "justifyContent": "center !important",
                    "maxWidth": "871px !important",
                    padding: 0
                  },
                  "fields": [
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "b893274rf",
                      "properties": {
                        "text": "Make sure that the following information is clearly visible:",
                        "classes": ["subHeading"],
                        "styles": {
                          "font-size": "16px;"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    { // Image
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/BraunEAN.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          "marginTop": "15px"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "fn": "goToPage",
                    "params": "receipt-upload"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "NEXT",
                  "addClass": "primaryButton",
                  "shouldCheckCrop": true,
                  "requires": ["3sxumo"],
                  "action": {
                    "fn": "sendProductPhotos",
                    "params": "3sxumo"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Registration
        "pageName": "register",
        "pageId": 43253214,
        // isPublic: true,
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": "12"
            },
            "fields": [
              {
                "fieldType": "progress-bar",
                "fieldId": "x6s2vxc",
                "properties": {
                  "currentInterval": 3,
                  "currentStep": 1,
                  "stepCount": 5
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "Registration form",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": "Register now and don't miss a thing.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": 'Braun informs you regularly about further "free test" campaigns and also offers exclusive free product tests every week.',
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              }
            ]
          },
          { // Form
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "4",
              "max-width": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "inzf4w",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "alignItems": "center",
                    "flexDirection": "column"
                  },
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "iban"
                  },
                  "apiUrl": "https://template-v2.justsnap.eu/api/register",
                  "fields": [
                    {
                      "fieldType": "card-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "10",
                          "marginTop": "60px"
                        },
                        "className": "whiteCard-fit whiteCard-32",
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Personal information",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "classes": ["description description-small"],
                              "styles": {
                                "margin": "0 0 23px 0 !important;"
                              }
                            }
                          },
                          {
                            "fieldType": "radio-input",
                            "fieldId": "Geschlecht",
                            "properties": {
                              "label": "Title",
                              "name": "gender",
                              "color": "#000",
                              "grid": {
                                "columns": 6,
                                "marginBottom": "20px"
                              },
                              "validation": "",
                              labelOnTop: true,
                              "direction": "row",
                              "options": [
                                {
                                  "value": "female",
                                  "text": "Mrs.",
                                  "disabled": false,
                                  "name": "firstRadio",
                                  tabIndex: 0
                                },
                                {
                                  "value": "male",
                                  "text": "Mr.",
                                  "disabled": false,
                                  "name": "secondRadio",
                                  tabIndex: 0
                                }
                              ]
                            }
                          },
                          {
                            "fieldId": "nameWrapper",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "className": "m-0 p-0",
                              "fields": [
                                {
                                  "fieldId": "b4baqs",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "given-name",
                                    "id": "given-name",
                                    "label": "First name",
                                    "placeholder": "Enter your first name",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required|namesWithHyphensAndSpaces",
                                    "autocomplete": "given-name",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "gofije",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "family-name",
                                    "id": "family-name",
                                    "label": "Last name",
                                    "placeholder": "Enter your last name",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required|namesWithHyphensAndSpaces",
                                    "autocomplete": "family-name",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldId": "dateWrapper",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": 12,
                              },
                              "className": "m-0 p-0 align-items-center",
                              "fields": [
                                {
                                  "fieldId": "godate",
                                  "fieldType": "date-input",
                                  "properties": {
                                    "name": "birthdate",
                                    "id": "birthdate",
                                    "label": "Date of birth",
                                    "placeholder": "dd/mm/yyyy",
                                    labelOnTop: true,
                                    "validation": "",
                                    tabIndex: 0,
                                    "grid": {
                                      "columns": "6"
                                    },
                                    "params": {
                                      "disabledDates": {
                                        "from": "18years",
                                        "to": "01.01.1900"
                                      }
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "h7kzf9",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "email",
                                    "id": "email",
                                    "label": "E-mail",
                                    "placeholder": "Enter your email",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required|email",
                                    autocomplete: "email",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Address",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "classes": ["description description-small"],
                              "styles": {
                                "margin": "22px 0 23px 0 !important;"
                              }
                            }
                          },
                          {
                            "fieldId": "addressWrapper",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": 12,
                              },
                              "className": "m-0 p-0 align-items-center",
                              "fields": [
                                {
                                  "fieldId": "b5q97u",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "street-address",
                                    "id": "street-address",
                                    "label": "Streetname",
                                    "placeholder": "Enter your streetname",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required",
                                    "autocomplete": "street-address",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "ql5mu4",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "house-number",
                                    "id": "house-number",
                                    "label": "House number",
                                    "placeholder": "Enter the number of your house",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "col6mobile": true,
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldId": "postalWrapper",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": 12,
                              },
                              "className": "m-0 p-0 align-items-center",
                              "fields": [
                                {
                                  "fieldId": "ql6mu5",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "postal-code",
                                    "id": "postal-code",
                                    "label": "Postal code",
                                    "placeholder": "Enter your postal code",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required|postal",
                                    "autocomplete": "postal-code",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "col6mobile": true,
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "jdtxik",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "address-level2",
                                    "id": "address-level2",
                                    "label": "City",
                                    "placeholder": "Enter a city you live",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required",
                                    "autocomplete": "address-level2",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldId": "countryWrapper",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": 12,
                              },
                              "className": "m-0 p-0 align-items-center",
                              "fields": [
                                {
                                  "fieldId": "x3d8oz",
                                  "fieldType": "select-input",
                                  "properties": {
                                    "name": "country",
                                    "id": "country",
                                    "label": "Country",
                                    "placeholder": "Choose a country",
                                    "text": "",
                                    labelOnTop: true,
                                    "validation": "required",
                                    "options": [
                                      {
                                        "text": "Denmark",
                                        "value": "Denmark"
                                      },
                                      {
                                        "text": "Norway",
                                        "value": "Norway"
                                      },
                                      {
                                        "text": "Finland",
                                        "value": "Finland"
                                      },
                                      {
                                        "text": "Sweden",
                                        "value": "Sweden"
                                      }
                                    ],
                                    "selectedValue": "",
                                    "grid": {
                                      "columns": 6
                                    },
                                    "styles": {
                                      "margin-bottom": "20px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Checkboxes
                      "fieldId": "8k2f023f",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "10"
                        },
                        "fields": [
                          {
                            "fieldType": "checkbox",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "name": "terms",
                              "id": "terms",
                              "validation": {
                                "required": {
                                  "allowFalse": false
                                }
                              },
                              "validationName": "Richtlinienbedingungen",
                              "validationMessage": "mandatory",
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "styles": {
                                "margin-top": "30px"
                              },
                              tabIndex: 0,
                              "label": "I confirm that I am over 18 years old. I confirm the terms and conditions, privacy policy & Justsnap privacy policy."
                            }
                          },
                          {
                            "fieldType": "checkbox",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "name": "campaignTermsAccepted",
                              "id": "campaignTermsAccepted",
                              "validation": {
                                "required": {
                                  "allowFalse": false
                                }
                              },
                              "validationName": "Teilnahmebedingungen",
                              "validationMessage": "mandatory",
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              tabIndex: 0,
                              "label": "I have read the Conditions of participation for this promotion and accept them."
                            }
                          },
                          {
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c",
                            "properties": {
                              "name": "receiveEmails",
                              "id": "receiveEmails",
                              "validationName": "Nutzungsbedigungen & Datenschutzrichtlinien",
                              "validationMessage": "mandatory",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              clickable: true,
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              tabIndex: 0,
                              "label": "Always up-to-date with our newsletters. I want to receive emails, offers and coupons from Oral-B - the community for power women and super moms - and other trusted P&G brands.",
                            }
                          },
                          { // Registration Notice
                            "fieldType": "paragraph",
                            "fieldId": "registration-notice",
                            "properties": {
                              "text": "Registration Notice",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12",
                                "marginTop": "20px"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "fn": "goToPage",
                    "params": "product-upload-be"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "NEXT",
                  "addClass": "primaryButton",
                  "action": {
                    "fn": "sendForm",
                    "params": "inzf4w"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Banking Page
        "pageName": "iban",
        "pageId": 3,
        "requires": [
          1
        ],
        "sections": [
          { // Step Counter and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "progress-bar",
                "fieldId": "x62vxc",
                "properties": {
                  "currentInterval": 3,
                  "currentStep": 0,
                  "stepCount": 5
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Enter bank details",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Please enter the first and last name of the account holder exactly as they were given at the bank.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['subHeading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  },
                  "styles": {
                    "fontSize": "16px"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Form Component
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "thank-you"
                  },
                  "apiUrl": "https://template-v2.justsnap.eu/api/submitIBAN",
                  "keepInState": ["referenceNumber"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center",
                    "font-family": 'Montserrat'
                  },
                  "fields": [
                    {
                      "fieldType": "card-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "marginTop": "60px"
                        },
                        "className": "whiteCard-fit whiteCard-32",
                        "fields": [
                          {
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN",
                              "placeholder": "Enter your IBAN",
                              "toolTip": {
                                "title": "What is IBAN and where do I find it?",
                                "text": `IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.`
                              },
                              "text": "",
                              labelOnTop: true,
                              "isUpperCase": true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "v1nf34",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "bic",
                              "id": "bic",
                              "label": "BIC",
                              "validation": "required|bic",
                              "placeholder": "Enter your BIC",
                              "toolTip": {
                                "title": "What is BIC and where do I find it?",
                                "text": "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC."
                              },
                              "text": "",
                              labelOnTop: true,
                              "isUpperCase": true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "given-name",
                              "id": "given-name",
                              "label": "Account holder first name",
                              "validation": "required|namesWithHyphensAndSpaces",
                              "placeholder": "Enter your first name",
                              "text": "",
                              labelOnTop: true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "family-name",
                              "id": "family-name",
                              "validation": "required|namesWithHyphensAndSpaces",
                              "label": "Account holder last name",
                              "placeholder": "Enter your last name",
                              "text": "",
                              labelOnTop: true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Disclaimer
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "6",
                    "marginTop": "15px"
                  },
                  "styles": {
                    "font-size": "13px"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "fn": "goToPage",
                    "params": "product-upload-be"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "NEXT",
                  "addClass": "primaryButton",
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "thank-you",
        "isPublic": true,
        "pageId": 99,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: 'consumerIdSend',
                  sendconsumerId: true
                }
              },
              {
                fieldId: "dte-004",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: "event_profile_login_complete"
                }
              },
              {
                "fieldType": "progress-bar",
                "fieldId": "x6s2vxc",
                "properties": {
                  "grid": {
                    columns: 12
                  },
                  "currentInterval": 4,
                  "currentStep": 0,
                  "stepCount": 1
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "Thank you for your participation",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": "Your receipt and your data have been successfully sent to us and are now being checked.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Ref and Image
                "fieldType": "empty-bucket",
                "fieldId": "emp1231258",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "justifyContent": "center",
                    "marginBottom": "50px",
                    "marginTop": "45px"
                  },
                  "fields": [
                    { // Reference Number
                      "fieldType": "empty-bucket",
                      "fieldId": "emp123123",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "flex-start",
                          "alignItems": "center"
                        },
                        "styles": {
                          "margin": "0 0 40px 0;",
                          "padding": "0"
                        },
                        "classes": "col-12",
                        "fields": [
                          { // Clipboard
                            "fieldType": "clipboard",
                            "fieldId": "ref123",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "padding-left": "0",
                                "padding-right": "0"
                              },
                              "text": "{referenceNumber}",
                              "disabledInput": true,
                              "variables": {
                                "referenceNumber": "referenceNumber"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Image
                      "fieldType": "empty-bucket",
                      "fieldId": "emp123123",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justify-content": "center",
                          media: {
                            query: "max-width: 991px",
                            grid: {
                              "display": "none"
                            }
                          }
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "/assets/banner/thank-you-page-banner.png",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 10
                              },
                              "className": "col-md-12 col-lg-6",
                              "styles": {
                                "maxHeight": "400px",
                                "object-fit": "contain"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with queries if your data was incomplete or your proof of purchase could not be recorded. This can take up to 36 hours. We ask for your patience.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "15px"
                  },
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;",
                    "margin": "40px 0 10px 0;"
                  }
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "After successfully checking the proof of purchase and all other data as well as succesfully receiving the product you would like to return, we will transfer the reimbursement amount to the account you specified within 6 weeks. If anything is unclear, we will contact you again by email.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  }
                }
              },
              { // Paragraph, return in 10 days
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "15px"
                  },
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;",
                    "font-weight": "bold;",
                    "margin": "40px 0 10px 0;"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "marginTop": "138px",
              "paddingBottom": "260px"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back to the Braun website",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "website-link"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Reclaim Page
        "pageName": "re-claim",
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "reclaim-bucket",
                "fieldId": "r3cla1m3uket",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "colorSchema": "#009639",
                  "apiUrl": "https://template-v2.justsnap.eu/api/reclaim",
                  "fields": [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "2fvjh65",
                      "properties": {
                        "text": "Ready",
                        "classes": ["heading topPadding"],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "b893274rf",
                      "properties": {
                        "text": "We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience",
                        "classes": ["subHeading"],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "26px"
                        }
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "b893274rf",
                      "properties": {
                        "text": "We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience",
                        "classes": ["subHeading"],
                        "styles": {
                          "font-size": "16px;"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "35px",
                          "marginBottom": "42px"
                        }
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/banner/doi-thank-you-banner.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          styles: {
                            "max-width": "1000px;"
                          }
                        }
                      }
                    }
                  ],
                  "failFields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "vnaped",
                      "properties": {
                        "text": "<p>Es ist ein Fehler aufgetreten</p>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "oql2db",
                      "properties": {
                        "text": "Derzeit liegt ein Problem bei unserer Website vor. Bitte versuchen Sie es in einigen Minuten erneut.",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "30vh"
                        }
                      }
                    }
                  ],
                  "notFoundFields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "vnaped",
                      "properties": {
                        "text": "<p>Ups, das hätte nicht passieren dürfen</p>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "oql2db",
                      "properties": {
                        "text": "Es liegt ein technischer Fehler vor. Bitte warte einen Augenblick und versuche es erneut.",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "30vh"
                        }
                      }
                    }
                  ],
                  "expiredFields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "vnaped",
                      "properties": {
                        "text": "<p>Uuupps… Leider ist sind die 48 Stunden bereits abgelaufen.</p>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "oql2db",
                      "properties": {
                        "text": "Bitte schreibe uns einfach eine E-Mail an contact@ariel-lenor-testwochen.de.<b>Bitte gebe in der E-Mail unbedingt deine persönliche Vorgangsnummer an.</b>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "30vh"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms and Conditions
        "pageName": "terms",
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
              "margin-bottom": "30px"
            },
            "fields": [
              {
                "fieldType": "conditions",
                "fieldId": "vabd69",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 12
                  }
                }
              }
            ]
          }
        ]
      },
      { // FAQ
        "pageName": "faq",
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
              "margin-bottom": "30px"
            },
            "fields": [
              {
                "fieldType": "faq",
                "fieldId": "vabd70",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "404",
        "pageId": 10424,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Oops!!",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading topPadding']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Something went wrong!",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Image
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              {
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/404.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "7",
                    "marginTop": "50px"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back to the homepage",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "website-link"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        "pageName": "expired",
        "pageId": 10425,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "jlsdjl",
                "properties": {
                  "text": "Unfortunately the link is no longer valid!",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading topPadding']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "jlsdjl",
                "properties": {
                  "text": "Please participate again or contact our support team: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "marginTop": "260px",
              "paddingBottom": "260px"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back to the homepage",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "home-link"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Campaign Is Over
        "pageName": "over",
        "pageId": 1041,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "start",
              "justifyContent": "center",
              "columns": "12"
            },
            "fields": [
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "The campaign period is over",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading topPadding']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Thank you for your interest in the campaign, unfortunately the campaign period is over.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "42px"
                  },
                  "classes": ['subHeading']
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "gb0wjv",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "position": "relative"
                  },
                  "fields": [
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/banner/campaign-is-over.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "marginTop": "260px",
              "paddingBottom": "260px"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back to the Braun website",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "website-link"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "receipt-re-upload",
        isPublic: true,
        "pageId": 102,
        "sections": [
          { //Query and  Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Re-Upload receipt",
                  "classes": ["heading topPadding"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Upload a picture of your proof of purchase in this step.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.",
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "justifyContent": "center",
              "marginTop": "90px"
            },
            "fields": [
              {
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Recipt upload
                "fieldType": "receipt-processor",
                "fieldId": "klvakj",
                "properties": {
                  texts: {
                    choose: 'Choose a File',
                    receiptUpload1: 'Upload your receipt photo',
                    receiptUpload2: 'Drag & Drop or click here',
                    wrongFormat: 'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.',
                    fileSizeLimit: 'The file is too big. Only files up to 16 MB are supported.'
                  },
                  "colorSchema": "#6BB1FF",
                  "grid": {
                    "columns": "6",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "addClass": "col-sm-9 col-md-6",
                  "successAction": {
                    "fn": "goToPage",
                    "params": "thank-you"
                  },
                  "reUpload": true,
                  "tokenName": "token",
                  "phrases": [
                    "Your receipt will now be uploaded and processed.",
                    "The receipt is processed automatically.This can take a minute.Please be patient.",
                    "If you get an error message, please take a better version of the receipt and upload it again."
                  ],
                  "cornerDetectionPhrases": [
                    "Please drag your receipt into the gray rectangle.",
                    "You can erase private information with the razor tool."
                  ],
                  "eraseColor": "#ffffff",
                  "eraseSize": 12,
                  "cropColor": "#3A96F3",
                  "cropBackground": "rgba(0,0,0,0.6)",
                  "cropTitle": "Crop",
                  "eraseTitle": "Erase",
                  "dragTitle": "Move",
                  "zoomInTitle": "Zoom In",
                  "zoomOutTitle": "Zoom Out",
                  "selectImageTitle": "Choose a File",
                  "selectAnotherTitle": "Choose Another",
                  "controlsBackground": "#4193d3",
                  "backgroundColorBtn": "var(--color2)"
                }
              },
              { // Receipt Info
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 6,
                    "margin": "0px auto"
                  },
                  "classes": "receiptUploader",
                  "fields": [
                    {
                      "fieldType": "receipt-information",
                      "fieldId": "5mq1p6",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "descriptionText": "Please photograph your proof of purchase!",
                        "descriptionSubText": "Make sure that the following information is clearly visible:",
                        "steps": [
                          {
                            "text": "Dealer / Market name",
                            "label": "A"
                          },
                          {
                            "text": "Promotional product and purchase price",
                            "label": "B"
                          },
                          {
                            "text": "Total of the proof of purchase and VAT",
                            "label": "C"
                          },
                          {
                            "text": "Date and time of purchase",
                            "label": "D"
                          },
                          {
                            "text": "Proof of purchase ID",
                            "label": "E"
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Receipt Examples
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "margin": "0px auto"
                  },
                  "styles": {
                    "justify-content": "center;"
                  },
                  "classes": "receiptUploader",
                  "fields": [
                    {
                      "fieldType": "receipt-examples",
                      "fieldId": "5mq1p6",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "justifyContent": "center"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "SEND",
                  "addClass": "primaryButton",
                  "shouldCheckCrop": true,
                  "requires": ["klvakj"],
                  "action": {
                    "fn": "sendReceipt",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      },
      { // Product Re Uploader Back
        "pageName": "product-re-upload-be",
        "isPublic": true,
        "pageId": 10312,
        "sections": [
          { // Query and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "vgw364bh",
            "fields": [
              { // Query
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "2fvjh65",
                "properties": {
                  "text": "Re-Upload product photo",
                  "classes": ["heading topPadding"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // SubHeading
                "fieldType": "paragraph",
                "fieldId": "b893274rf",
                "properties": {
                  "text": "Please upload a photo of the product side on which the EAN barcode is clearly visible.",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "v23n9ewp",
                "properties": {
                  "text": "Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.",
                  "classes": ["subHeading"],
                  "styles": {
                    "font-size": "16px;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  }
                }
              }
            ]
          },
          { // Product Re Uploader
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "90px",
              "marginBottom": "90px"
            },
            "fields": [
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "5"
                  },
                  "styles": {
                    "justifyContent": "center !important",
                    "maxWidth": "871px !important",
                    padding: 0
                  },
                  "fields": [
                    {
                      "fieldType": "product-upload",
                      "fieldId": "cakl305",
                      "properties": {
                        texts: {
                          choose: 'Choose a File',
                          receiptUpload1: 'Upload product photo',
                          receiptUpload2: 'Drag & Drop or click here',
                          wrongFormat: 'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.',
                          fileSizeLimit: 'The file is too big. Only files up to 16 MB are supported.'
                        },
                        "colorSchema": "var(--color2)",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "reupload": {
                          "status": true
                        },
                        "reUpload": true,
                        "tokenName": "token",
                        "apiUrl": "https://template-v2.justsnap.eu/api/uploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you"
                        }
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "5"
                  },
                  "styles": {
                    "justifyContent": "center !important",
                    "maxWidth": "871px !important",
                    padding: 0
                  },
                  "fields": [
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "b893274rf",
                      "properties": {
                        "text": "Make sure that the following information is clearly visible:",
                        "classes": ["subHeading"],
                        "styles": {
                          "font-size": "16px;"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        }
                      }
                    },
                    { // Image
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/BraunEAN.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          "marginTop": "15px"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "justifyContent": "center"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "SEND",
                  "addClass": "primaryButton",
                  "shouldCheckCrop": true,
                  "requires": ["cakl305"],
                  "action": {
                    "fn": "sendProductPhotos",
                    "params": "cakl305"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "re-iban",
        "isPublic": true,
        "pageId": 104,
        "requires": [
          1
        ],
        "sections": [
          { // HEADING AND SUBHEADING
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },

            "fields": [
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Enter bank details",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading topPadding']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Please enter the first and last name of the account holder exactly as they were given at the bank.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['subHeading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  },
                  "styles": {
                    "fontSize": "16px"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Form Component
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "thank-you-iban"
                  },
                  "apiUrl": "https://template-v2.justsnap.eu/api/updateIBAN",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center",
                    "font-family": 'Montserrat'
                  },
                  "fields": [
                    {
                      "fieldType": "card-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "marginTop": "60px"
                        },
                        "className": "whiteCard-fit whiteCard-32",
                        "fields": [
                          {
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN",
                              "placeholder": "Enter your IBAN",
                              "toolTip": {
                                "title": "What is IBAN and where do I find it?",
                                "text": `IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.`
                              },
                              "text": "",
                              labelOnTop: true,
                              "isUpperCase": true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "v1nf34",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "bic",
                              "id": "bic",
                              "label": "BIC",
                              "validation": "required|bic",
                              "placeholder": "Enter your BIC",
                              "toolTip": {
                                "title": "What is BIC and where do I find it?",
                                "text": "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC."
                              },
                              "text": "",
                              labelOnTop: true,
                              "isUpperCase": true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "given-name",
                              "id": "given-name",
                              "label": "Account holder first name",
                              "validation": "required|namesWithHyphensAndSpaces",
                              "placeholder": "Enter your first name",
                              "text": "",
                              labelOnTop: true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          {
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "family-name",
                              "id": "family-name",
                              "validation": "required|namesWithHyphensAndSpaces",
                              "label": "Account holder last name",
                              "placeholder": "Enter your last name",
                              "text": "",
                              labelOnTop: true,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Disclaimer
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "6",
                    "marginTop": "15px"
                  },
                  "styles": {
                    "font-size": "13px"
                  }
                }
              }
            ]
          },
          { // BUTTONS
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "justifyContent": "center"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "SEND",
                  "addClass": "primaryButton",
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        "pageId": 10431,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "start",
              "justifyContent": "center",
              "columns": "12",
              "maxWidth": "1440px"
            },
            "fields": [
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Thank you",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['heading topPadding']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Thank you for resubmitting your bank details.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ['subHeading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "So nothing should stand in the way of a successful transfer.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  },
                  "styles": {
                    "fontSize": "16px"
                  },
                  "classes": ['subHeading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "If you have any questions related to your bank details, please contact <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "35px"
                  },
                  "styles": {
                    "fontSize": "16px"
                  },
                  "classes": ['subHeading']
                }
              },
              { // Subheading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "For other concerns, you can contact our support service at <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "fontSize": "16px"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "marginTop": "260px",
              "paddingBottom": "260px"
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "gfdg32",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Back to the Braun website",
                  "addClass": "backButton absolute left",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "website-link"
                  }
                }
              }
            ]
          }
        ]
      },
      { // ::PAGE Survey
        "pageName": "survey",
        "pageId": 5237,
        isPublic: true,
        "sections": [
          { // Steps and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px"
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Survey",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "20px",
                    "marginTop": "10vh"
                  }
                }
              }
            ]
          },
          { // Form
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "margin": "auto",
              "marginBottom": "50px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "receiptToken",
                      state: "receiptToken"
                    }
                  ]
                }
              },
              { // Survey component
                "fieldType": "survey",
                "fieldId": "sur100",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "justifyContent": "flex-start"
                  },
                  "mandatory": false,
                  "apiUrl": "https://template-v2.justsnap.eu/api/survey",
                  "successAction": {
                    "fn": "goToPage",
                    "params": "return-form"
                  },
                  "questions": [
                    {
                      name: "checkbox-1",
                      type: "checkbox",
                      text: "What were the main reasons for you to buy this product?",
                      subText: "Please select as applicable; You have a multiple choice.",
                      options: ["Easy to use", "Design", "Price/Good deal", "Performance", "Other"],
                      otherOption: true,
                      otherText: "Enter optional"
                    },
                    {
                      name: "checkbox-2",
                      type: "checkbox",
                      text: "Why would you like to return the product?",
                      subText: "You have a multiple choice.",
                      options: ["It doesn't work as expected", "Wrong item size or wrong product", "Changed mind after product purchased", "Product didn't match the website or catalog description", "Found better product price elsewhere", "Product did not meet my expectations"],
                      otherOption: false
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 10,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px"
            },
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "NEXT",
                  "addClass": "primaryButton",
                  "action": {
                    "fn": "sendSurvey",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      },
      { // ::PAGE Return Form
        "pageName": "return-form",
        "pageId": 43253754,
        isPublic: true,
        "sections": [
          { // Steps and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px"
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Return form",
                  "classes": ["heading topPadding"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              }
            ]
          },
          { // Form
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "return-form-component",
                "fieldId": "gdj2h3",
                "properties": {
                  styles: {
                    "display": " block;",
                    "text-align": "center;",
                    "font-size": "30px;",
                    "margin-top": "30px;",
                    "margin-bottom": "0.83em;",
                    "margin-left": "0;",
                    "margin-right": "0;",
                    "font-weight": "bold;"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px",
                    "marginBottom": "50px"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12
            },
            "className": "button-section",
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "Print",
                  "addClass": "primaryButton",
                  "requires": ["gdj2h3"],
                  "action": {
                    "fn": "printForm",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "backgroundColor": "#000",
        "title": "",
        "color": "#F5F5F5",
        "grid": {
          "columns": 12
        },
        "logo": {
          "imageSource": "/assets/logo/braun_logo_header.svg",
          "width": 86,
          "alternateText": "Braun logo"
        },
        "favicon": {
          "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
        },
        "languages": [
          { text: "Danmark", code: "dk", flag: "/assets/flags/denmark.svg" },
          { text: "Norge", code: "no", flag: "/assets/flags/norway.svg" },
          { text: "Suomi", code: "fi", flag: "/assets/flags/finland.svg" },
          { text: "Sverige", code: "se", flag: "/assets/flags/sweden.svg" }
        ],
        "fields": [
          {
            "fieldId": "ikz8v12c",
            "fieldType": "anchor",
            "properties": {
              "text": 'Skip to Main Content',
              "url": "#main-content",
              "target": "",
              "cssClass": "nav-link headingLink"
            }
          },
          {
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": 'Terms and Conditions',
              "url": "/terms",
              "target": "_blank",
              "cssClass": "nav-link headingLink"
            }
          },
          {
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": 'FAQ',
              "url": "/faq",
              "target": "_blank",
              "cssClass": "nav-link headingLink"
            }
          }
        ],
        "showLine": true,
        "meta": [
          { "name": "title", "content": "Braun Zahncreme Gratis Testen" },
          {
            "name": "description",
            "content": `Braun Testen Aktion - Großartige Sonderaktionen, Gratis Testen, Angebote. Alle Aktionen auf einen Blick! `
          },
          {
            "name": "keywords",
            "content": `Braun Zahnfleisch und -schmelz Original, ‎Braun PROFESSIONAL Zahnfleisch und -schmelz Original , Braun Professional Sensitivität und Zahnfleischbalsam Sanfte Reinigung , ‎Braun Kinderzahncreme Junior ab 6 J 75ml Star Wars`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz"
    },
    "errors": {
      "en": {
        default: 'Oops. An error has occurred. Please try again later.',
        iban: '',
        ibanUsed: 'This IBAN has already been used together with another email address.',
        emailUsed: 'An account is already registered with this email. Please log in with this email on the login page.',
        receipt: 'An error has occurred. Please update the website.',
        product: '',
        survey: 'Survey error',
        server: 'There is currently a problem with our website. Please try again in a few minutes.',
        technical: 'There is a technical error. Please wait a moment and try again.'
      },
      "dk": {
        default: 'Ups. Der er opstået en fejl. Prøv venligst igen senere.',
        iban: '',
        ibanUsed: 'Dette IBAN er allerede blevet brugt sammen med en anden e-mailadresse.',
        emailUsed: 'Der er allerede registreret en-konto med denne e-mail. Du bedes logge ind med denne e-mail på login-siden.',
        receipt: 'Der er opstået en fejl. Opdater venligst hjemmesiden.',
        product: '',
        survey: '',
        server: 'Der er i øjeblikket et problem med vores websted. Prøv venligst igen om et par minutter.',
        technical: 'Der er sket en teknisk fejl. Vent venligst et øjeblik og prøv igen.'
      },
      "fi": {
        default: 'Hups. On tapahtunut virhe. Yritä myöhemmin uudelleen.',
        iban: '',
        ibanUsed: 'Tätä IBAN-tilinumeroa on jo käytetty yhdessä toisen sähköpostiosoitteen kanssa.',
        emailUsed: 'Tällä sähköpostilla on jo rekisteröity tili. Ole hyvä ja kirjaudu sisään tällä sähköpostilla kirjautumissivulla.',
        receipt: 'On tapahtunut virhe. Ole hyvä ja päivitä verkkosivusto.',
        product: '',
        survey: '',
        server: 'Verkkosivustossamme on tällä hetkellä ongelma. Yritä uudelleen muutaman minuutin kuluttua.',
        technical: 'On tapahtunut tekninen virhe. Odota hetki ja yritä uudelleen.'
      },
      "no": {
        default: 'Oops. Det har oppstått en feil. Prøv på nytt senere.',
        iban: '',
        ibanUsed: 'Denne IBAN-en er allerede brukt sammen med en annen e-postadresse.',
        emailUsed: 'En-konto er allerede registrert med denne e-posten. Logg inn med denne e-posten på innloggingssiden.',
        receipt: 'Det har oppstått en feil. Oppdater webområdet.',
        product: '',
        survey: '',
        server: 'Det er for øyeblikket et problem med nettstedet vårt. Prøv på nytt om noen minutter.',
        technical: 'Det er en teknisk feil. Vent litt, og prøv på nytt.'
      },
      "se": {
        default: 'Oops. Ett fel har inträffat. Försök igen senare.',
        iban: '',
        ibanUsed: 'Detta IBAN har redan använts tillsammans med en annan e-postadress.',
        emailUsed: 'Ett ""-konto är redan registrerat med denna e-postadress. Vänligen logga in med denna e-postadress på inloggningssidan.',
        receipt: 'Ett fel har uppstått. Vänligen uppdatera webbplatsen.',
        product: '',
        survey: '',
        server: 'Det finns för närvarande ett problem med vår webbplats. Försök igen om några minuter.',
        technical: 'Det har uppstått ett tekniskt fel. Vänligen vänta ett ögonblick och försök igen.'
      }
    },
    "campaignId": "braun-moneyback",
    "url": "braun-moneyback.de",
    "name": "braun-moneyback"
  },
  pageBuilder: {
    selectedField: {
      sectionId: '',
      fieldId: generateUID()
    }
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  campaignId: 'braun-moneyback-dk',
  formData: new FormData(),
  campaignEndDate: '2025-11-01',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
  errorImageList: ["", ""],
  errorActivate: false,
  scannedProducts: [],
  types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
  pageToBack: '',
  receiptBarcode: '',
  isReceiptImageChanged: false,
  convertedImagePointsData: {},
  firstTimeUploading: true,
  removeLogo: false,
  currentStep: 1,
  referenceNumber: ''
}
